var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-tabs",
            { attrs: { color: _vm.colorx } },
            [
              _c("vs-tab", { attrs: { label: _vm.$t("plan.title") } }, [
                _c("div", { staticClass: "flex flex-col gap-4" }, [
                  _vm.isEdit()
                    ? _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full required",
                            attrs: {
                              id: "plan_name",
                              label: _vm.$t("plan.name"),
                            },
                            model: {
                              value: _vm.model.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isEdit()
                    ? _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c(
                            "vx-input-group",
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:191",
                                    expression: "'required|max:191'",
                                  },
                                ],
                                staticClass: "w-full required",
                                attrs: {
                                  id: "plan_name",
                                  label: _vm.$t("plan.name"),
                                },
                                model: {
                                  value: _vm.model.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "name", $$v)
                                  },
                                  expression: "model.name",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("plan_name"),
                                      expression: "errors.has('plan_name')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [_vm._v(_vm._s(_vm.errors.first("plan_name")))]
                              ),
                              _c("template", { slot: "append" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "append-text btn-addon flex items-end",
                                  },
                                  [
                                    _c("vs-button", {
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "search",
                                        title: _vm.$t("buscar"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPlanPopup = true
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c("plan-popup", {
                            attrs: { visible: _vm.showPlanPopup },
                            on: {
                              selected: _vm.addPlanFromPopup,
                              close: _vm.closePlanPopup,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEdit()
                    ? _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "material-icons",
                              icon: _vm.accountIcon,
                              id: "account",
                              label: _vm.isInstitution
                                ? _vm.$t("plan.institution")
                                : _vm.$t("plan.user"),
                              readonly: "",
                            },
                            model: {
                              value: _vm.model.account_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "account_name", $$v)
                              },
                              expression: "model.account_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isEdit()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c(
                                "label",
                                {
                                  class: _vm.isInstitution
                                    ? "text-primary"
                                    : "",
                                },
                                [_vm._v(_vm._s(_vm.$t("plan.institution")))]
                              ),
                              _c("vs-switch", {
                                staticClass: "bg-primary mr-2 ml-2",
                                model: {
                                  value: _vm.isInstitution,
                                  callback: function ($$v) {
                                    _vm.isInstitution = $$v
                                  },
                                  expression: "isInstitution",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  class: _vm.isInstitution
                                    ? ""
                                    : "text-primary",
                                },
                                [_vm._v(_vm._s(_vm.$t("plan.user")))]
                              ),
                            ],
                            1
                          ),
                          _vm.isInstitution
                            ? _c(
                                "div",
                                { staticClass: "flex col-span-2" },
                                [
                                  _c("select-suggestion", {
                                    ref: "select_institution",
                                    staticClass: "flex-grow required",
                                    attrs: {
                                      max: 20,
                                      column: "name",
                                      model: "Institution",
                                      label: _vm.$t("fields.institution"),
                                      appendClearOption: true,
                                      placeholderText: _vm.$t(
                                        "placeholders.select_suggestion.institution"
                                      ),
                                    },
                                    model: {
                                      value: _vm.institution,
                                      callback: function ($$v) {
                                        _vm.institution = $$v
                                      },
                                      expression: "institution",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("institution"),
                                          expression:
                                            "errors.has('institution')",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("institution"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isInstitution
                            ? _c(
                                "div",
                                { staticClass: "flex col-span-2" },
                                [
                                  _c("select-suggestion", {
                                    ref: "select_user",
                                    staticClass: "flex-grow required",
                                    attrs: {
                                      column: "name,email",
                                      model: "User",
                                      color: "dark",
                                      label: _vm.$t("usuario"),
                                      appendClearOption: true,
                                      placeholderText: _vm.$t(
                                        "placeholders.select_suggestion.user_email"
                                      ),
                                      itemTextFormat: (model) =>
                                        `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`,
                                    },
                                    model: {
                                      value: _vm.user,
                                      callback: function ($$v) {
                                        _vm.user = $$v
                                      },
                                      expression: "user",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 gap-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass:
                                "w-full sm:w-1/2 flex-shrink required",
                              attrs: {
                                id: "plan_currency_type",
                                label: _vm.$t("plan.currency_type"),
                              },
                              model: {
                                value: _vm.model.currency_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "currency_type", $$v)
                                },
                                expression: "model.currency_type",
                              },
                            },
                            _vm._l(
                              _vm.currencyTypeList,
                              function (item, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  attrs: { value: item.value, text: item.text },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_currency_type"),
                                  expression:
                                    "errors.has('plan_currency_type')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("plan_currency_type"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              id: "plan_price_value",
                              lang: "pt-BR",
                              type: "number",
                              label: _vm.$t("billing.payment_value"),
                            },
                            model: {
                              value: _vm.model.payment_value,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "payment_value", $$v)
                              },
                              expression: "model.payment_value",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_price_value"),
                                  expression: "errors.has('plan_price_value')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("plan_price_value"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              id: "plan_implementation_value",
                              lang: "pt-BR",
                              type: "number",
                              label: _vm.$t("billing.implementation_value"),
                            },
                            model: {
                              value: _vm.model.implementation_value,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "implementation_value", $$v)
                              },
                              expression: "model.implementation_value",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has(
                                    "plan_implementation_value"
                                  ),
                                  expression:
                                    "errors.has('plan_implementation_value')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.errors.first("plan_implementation_value")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "numeric|max:3",
                                expression: "'numeric|max:3'",
                              },
                            ],
                            attrs: {
                              id: "plan_duration",
                              type: "number",
                              maxlength: "3",
                              label: _vm.$t("plan.duration_in_day"),
                            },
                            on: { keypress: _vm.isNumberInteger },
                            model: {
                              value: _vm.model.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "duration", $$v)
                              },
                              expression: "model.duration",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("plan_duration"),
                                  expression: "errors.has('plan_duration')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("plan_duration")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("label", { staticClass: "text-sm pl-1" }, [
                        _vm._v(_vm._s(_vm.$t("plan.description"))),
                      ]),
                      _c("complex-editor", {
                        attrs: { editor_data: _vm.model.description },
                        on: {
                          "update:editor_data": function ($event) {
                            return _vm.$set(_vm.model, "description", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full flex gap-2 justify-end" },
                    [
                      !_vm.isEdit()
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "billing.create",
                                  expression: "'billing.create'",
                                },
                              ],
                              attrs: { disabled: !_vm.validateForm },
                              on: { click: _vm.createOrUpdate },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isEdit()
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "billing.edit",
                                  expression: "'billing.edit'",
                                },
                              ],
                              attrs: { disabled: !_vm.validateForm },
                              on: { click: _vm.createOrUpdate },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "vs-button",
                        {
                          attrs: { type: "border" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.isEdit()
                ? _c("vs-tab", { attrs: { label: _vm.$t("plan.usage") } }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col gap-4" },
                      [
                        _c("PlanUsageForm", {
                          attrs: { plan_billing_id: _vm.id },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-full flex gap-2 pt-6 justify-end" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { type: "border" },
                            on: { click: _vm.cancel },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }